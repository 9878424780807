import React, { useState, useEffect } from 'react';
import moment from 'moment'
import { isEqual } from 'lodash'

import { formatDateTime, isTimeAfterEventStart, getIsTimeAfterEventStartErrorMsg, isTimeBeforeEventVisibility, getIsTimeBeforeEventVisibilityErrorMsg, isTimeAfterGeneralOnsale, getIsTimeAfterGeneralOnsaleErrorMsg } from '../../../utilities/helpers';

import Stack from 'react-bootstrap/Stack';
import Button from 'react-bootstrap/Button';

import { EventModal } from './EventModal';
import { RequiredText } from '../../RequiredText';

export default function EventDetails({ event, timezone, isEdit, eventStart, isEditable, isEventPublished, isEventOnsale, isEventSoldout, setDoorsOpen, doorsOpenOffset, setDoorsOpenOffset, eventVisibility, setEventVisibility, generalOnsale, setGeneralOnsale, generalOnsaleEnd, requiredFieldErrorStatus }) {

    // doors open offset in case of discard 
    const [doorsOpenOffsetLocal, setDoorsOpenOffsetLocal] = useState({
        time: '2',
        unit: 'hour'
    });

    // local date in case of discard
    const [eventVisibilityLocal, setEventVisibilityLocal] = useState(null);

    // local date in case of discard
    const [generalOnsaleLocal, setGeneralOnsaleLocal] = useState(null);

    const [doorsOpenText, setDoorsOpenText] = useState('2 hours');

    const [eventVisibilityText, setEventVisibilityText] = useState('As soon as the event is published');

    const [generalOnsaleText, setGeneralOnsaleText] = useState('No general on-sale date set');

    const [action, setAction] = useState('')

    const [show, setShow] = useState(false)

    const [timeError, setTimeError] = useState(false)

    const [timeErrorMsg, setTimeErrorMsg] = useState('')

    useEffect(() => {
        // if editing
        if (isEdit) {
            // set only on initial load to get values from backend 

            // if not equal - only on first time
            if (!isEqual(doorsOpenOffsetLocal, doorsOpenOffset)) {
                // set doorsOpenOffset local to global one 
                setDoorsOpenOffsetLocal(doorsOpenOffset)
                setDoorsOpenText(getText(doorsOpenOffset?.time, doorsOpenOffset?.unit))
            }

            // update local state with props 
            // if not already set and global eventVisibility is set to date - can change even if local is set  
            if (eventVisibility) {
                setEventVisibilityLocal(eventVisibility)
                setEventVisibilityText(getText(undefined, undefined, eventVisibility))
            }

            // if not already set 
            if (!generalOnsaleLocal && generalOnsale) {
                setGeneralOnsaleLocal(generalOnsale)
            }
        }
    }, [eventStart, doorsOpenOffset, eventVisibility, generalOnsale])

    // update general onsale text everytime general onsale and general onsale end time changes only if general onsale is set 
    useEffect(() => {
        if (generalOnsale) {
            setGeneralOnsaleText(getText(undefined, undefined, generalOnsale, generalOnsaleEnd))
        }
    }, [generalOnsale, generalOnsaleEnd])

    useEffect(() => {

    }, [event])

    // validation for event visibility 
    // - cannot be after event start time
    // validation for event general onsale 
    // - cannot be before event visibility - will catch if event visibility is after general onsale 
    // - cannot be after event start time
    useEffect(() => {
        setTimeError((isTimeBeforeEventVisibility(generalOnsaleLocal, event, eventVisibilityLocal) || isTimeAfterEventStart(eventVisibilityLocal, event, eventStart) || isTimeAfterEventStart(generalOnsaleLocal, event, eventStart)))
        setTimeErrorMsg(getErrorMsg())
    }, [action, eventVisibilityLocal, generalOnsaleLocal])

    // validation for event visibility: 
    // - cannot be after event start date
    // - cannot be after general onsale start date 
    // validation for event general onsale: 
    // - cannot be before event visibility date
    // - cannot be after event start date 
    const getErrorMsg = () => {
        if ((action === 'visibility' && isTimeAfterGeneralOnsale(eventVisibilityLocal, event, generalOnsaleLocal))) {
            return getIsTimeAfterGeneralOnsaleErrorMsg(event, generalOnsaleLocal)
        } else if (action === 'onsale' && isTimeBeforeEventVisibility(generalOnsaleLocal, event, eventVisibilityLocal)) {
            return getIsTimeBeforeEventVisibilityErrorMsg(event, eventVisibilityLocal)
        }
        else if (isTimeAfterEventStart(eventVisibilityLocal, event, eventStart) || isTimeAfterEventStart(generalOnsaleLocal, event, eventStart)) {
            return getIsTimeAfterEventStartErrorMsg(event, eventStart)
        }
        else return ''
    }

    const handleShow = (action) => {
        setAction(action)
        setShow(true)
    }

    const handleClose = () => {
        setShow(false)
        setAction('')
    }

    const handleDoorsOpen = (e) => {
        const splitArr = e.target.value.split(' ');
        setDoorsOpenOffsetLocal({ time: splitArr[0], unit: splitArr[1] })
    }

    const handleSubmit = (action) => {
        switch (action) {
            case 'doorsTime':
                const eventDoorsOpenTime = moment(eventStart).subtract(doorsOpenOffsetLocal.time, `${doorsOpenOffsetLocal.unit}s`)
                setDoorsOpen(new Date(eventDoorsOpenTime))
                setDoorsOpenOffset(doorsOpenOffsetLocal)
                setDoorsOpenText(getText(doorsOpenOffsetLocal.time, doorsOpenOffsetLocal.unit))
                break;

            case 'visibility':
                setEventVisibilityText(getText(undefined, undefined, eventVisibilityLocal))
                setEventVisibility(eventVisibilityLocal)
                break;

            case 'onsale':
                setGeneralOnsaleText(getText(undefined, undefined, generalOnsaleLocal, generalOnsaleEnd))
                setGeneralOnsale(generalOnsaleLocal)
                break;

            default:
                break;
        }
        handleClose()
    }

    const handleCancel = (action) => {
        switch (action) {
            case 'doorsTime':
                setDoorsOpenOffsetLocal(doorsOpenOffset)
                break;

            case 'visibility':
                setEventVisibilityLocal(eventVisibility)
                break;

            case 'onsale':
                setGeneralOnsaleLocal(generalOnsale)
                break;

            default:
                break;
        }
        handleClose()
    }

    const getText = (time, unit, date, end) => {
        // doors open
        if (time && unit) return `${time} ${time == 1 ? `${unit}` : `${unit}s`}`

        // general onsale 
        else if (end) return `${formatDateTime(moment(date), 'dateOnly')} \u2022 ${formatDateTime(moment(date), 'timeOnly')} \u2192 ${formatDateTime(moment(end), 'dateOnly')} \u2022 ${formatDateTime(moment(end), 'timeOnly')}`

        // visibility
        else if (date) return `${formatDateTime(moment(date), 'dateOnly')} \u2022 ${formatDateTime(moment(date), 'timeOnly')}`
    }

    // modal Apply button 
    const checkIsDisabled = () => {
        switch (action) {
            case 'doorsTime':
                return doorsOpenOffset.time === doorsOpenOffsetLocal.time && doorsOpenOffset.unit === doorsOpenOffsetLocal.unit

            // only one has to be true to make this true
            case 'visibility':
                return !eventVisibilityLocal || isEqual(eventVisibility, eventVisibilityLocal)

            case 'onsale':
                return (isEqual(generalOnsale, generalOnsaleLocal))

            default:
                break;
        }
    }

    return (
        <>
            <Stack gap={2} className='event-details offset-container'>
                <Button
                    variant="default"
                    className='list-item list-item-sm btn-list-view btn-edit-after'
                    onClick={() => handleShow('doorsTime')}
                    disabled={isEditable}
                >
                    <div className='heading--flex'>
                        <Stack>
                            <h1 className='normal normal-bold m-0'>Doors Time</h1>
                            <small className='subtitle'>{doorsOpenText} before start time</small>
                        </Stack>
                    </div>
                </Button>
                <Button
                    variant="default"
                    className='list-item list-item-sm btn-list-view btn-edit-after'
                    onClick={() => handleShow('visibility')}
                    disabled={isEditable || isEventPublished}
                >
                    <div className='heading--flex'>
                        <Stack>
                            <h1 className='normal normal-bold m-0'>Event Visibility</h1>
                            <small className='subtitle'>{eventVisibilityText}</small>
                        </Stack>
                    </div>
                </Button>
                <Button
                    variant="default"
                    className='list-item list-item-sm btn-list-view btn-edit-after'
                    onClick={() => handleShow('onsale')}
                    disabled={isEditable || isEventOnsale || isEventSoldout}
                >
                    <div className='heading--flex'>
                        <Stack>
                            <div className='flex flex-sm'>
                                <h1 className='normal normal-bold m-0'>General On-sale</h1>
                                {requiredFieldErrorStatus?.generalOnsale && (<RequiredText />)}
                            </div>
                            <small className='subtitle'>{generalOnsaleText}</small>
                        </Stack>
                    </div>
                </Button>
            </Stack>

            <EventModal
                show={show}
                setShow={setShow}
                eventAction={action}
                isDisabled={checkIsDisabled()}
                timezone={timezone}
                eventStart={eventStart}
                doorsOpenOffset={doorsOpenOffsetLocal}
                handleDoorsOpen={handleDoorsOpen}
                eventVisibility={eventVisibilityLocal}
                setEventVisibility={setEventVisibilityLocal}
                generalOnsale={generalOnsaleLocal}
                setGeneralOnsale={setGeneralOnsaleLocal}
                generalOnsaleEnd={generalOnsaleEnd}
                error={timeError}
                errorMsg={timeErrorMsg}
                handleSubmit={handleSubmit}
                handleCancel={handleCancel}
            />
        </>
    )
}