import React, { useEffect } from "react";
import { Rect } from "react-konva";

export default function Seat({
  selectSeat,
  seat,
  activeMapAction,
  hovering,
  darkenColor,
  seatMouseToggle,
  seatInfo
}) {
  
  const handleMouseEnter = (e) => {
    const container = e.target.getStage().container();
    container.style.cursor = "pointer";
    seatMouseToggle(seat.seatId, true);
  };

  const handleMouseLeave = (e) => {
    const container = e.target.getStage().container();
    container.style.cursor = "default";
    seatMouseToggle(seat.seatId, false);
  };

  const handleClick = () => {
    selectSeat(seat.seatId);
  };

  return (
    <Rect
      x={seat.cx}
      y={seat.cy}
      id={seat.seatId}
      cornerRadius={20}
      className='seat'
      width={seat.w}
      height={seat.h}
      fill={darkenColor(seat?.seatId)}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleClick}
      listening={![1, 2, 4].includes(activeMapAction)}
    ></Rect>
  );
}