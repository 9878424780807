import React, { useRef, useState, useEffect } from "react";
import { Path, Layer, Group } from "react-konva";
import chroma from "chroma-js";
import { InventoryInfo } from "./InventoryInfo";
import { mapStateManager } from "../MapStateManager";

const Sections = React.memo(
  ({
    data,
    selectRegularSeats,
    clickGASection,
    activeMapAction,
    activeTab,
    inventory
  }) => {
    const [hoveredSectionId, setHoveredSectionId] = useState(null);
    const darkenColor = (color, amount) => chroma(color).darken(amount).hex();

    const handleSectionAction = (e, sectionId, hovering) => {
      applyFilterToSection(sectionId, hovering);
    };

    const applyFilterToSection = (sectionId, hovering) => {
      const section = data.sections[sectionId];
      if (!section) return;

      // For non-zoomable sections, just update the section
      if (section.rows.length === 0) {
        mapStateManager.setState(sectionId, { selected: hovering });
        return;
      }

      setHoveredSectionId(hovering ? sectionId : null);
    };

    const handleSectionSelect = (section) => {
      console.log("handleSectionSelect");
      // Handle GA and seated differently
      if (section?.zoomable) {
        // seated
        const seatIds = getSeatIdsForZoomableSection(
          section,
          data.rows,
          data.seats
        );
        selectRegularSeats(undefined, seatIds);
      } else {
        clickGASection(data.sections[section.sectionId]);
      }
    };

    const getSeatIdsForZoomableSection = (section, rows, seats) => {
      return Object.values(rows)
        .filter((row) => row.sectionId === section.sectionId)
        .flatMap((row) => row.seats)
        .filter((seatId) => {
          if (!seats[seatId]) return false;
          // dont include sold seats
          if (
            inventory.getSeatItem(seatId) &&
            inventory.getSeatItem(seatId)?.sold
          ) {
            return false;
          }
          // seats[seatId].selected = true;
          return true;
        });
    };

    return (
      <Layer>
        {data?.sections &&
          Object.values(data.sections).map((section) => {
            return section?.zoomable ? (
              <Path
                key={section.sectionId}
                data={section.path}
                id={section.sectionId}
                onClick={() => handleSectionSelect(section)}
                onMouseOver={(e) =>
                  handleSectionAction(e, section.sectionId, true)
                }
                onMouseOut={(e) =>
                  handleSectionAction(e, section.sectionId, false)
                }
                listening={activeMapAction === 1}
                opacity={hoveredSectionId === section.sectionId ? 0.2 : 0}
                fill="#3E8BF7"
              ></Path>
            ) : (
              <GASection
                key={section?.sectionId}
                section={section}
                handleSectionAction={handleSectionAction}
                handleSectionSelect={handleSectionSelect}
                activeTab={activeTab}
                inventory={inventory}
                darkenColor={darkenColor}
              />
            );
          })}
      </Layer>
    );
  }
);

function GASection({
  section,
  handleSectionAction,
  handleSectionSelect,
  activeTab,
  inventory,
  darkenColor,
}) {
  const visualRef = useRef();
  const [sectionState, setSectionState] = useState(mapStateManager.getState(section.sectionId));

  useEffect(() => {
    return mapStateManager.subscribe((updatedSectionId) => {
      if (updatedSectionId === section.sectionId) {
        setSectionState(mapStateManager.getState(section.sectionId))
      }
    })
  }, [section])

  const getSectionFillColor = (section) => {
    if (inventory.isSectionSelected(section.sectionId)) {
      return "rgba(20, 20, 22, 1)";
    }
    // Check if it's a GA section and get its color
    const gaColor = inventory.getGASectionColor(section.sectionId);
    if (gaColor && activeTab === "scaling") {
      return sectionState?.selected ? darkenColor(gaColor, 1) : gaColor;
    }

    if (sectionState?.selected) {
      return darkenColor(section.fill, 1);
    }

    return section.fill;
  };

  return (
    <Group
      onClick={() => handleSectionSelect(section)}
      onMouseOver={(e) => handleSectionAction(e, section.sectionId, true)}
      onMouseOut={(e) => handleSectionAction(e, section.sectionId, false)}
      listening={true}
    >
      {/* Section */}
      <Path
        fill={getSectionFillColor(section)}
        data={section.path}
        className={`path`}
        listening={true}
        transformsEnabled={"position"}
        perfectDrawEnabled={false}
        id={section.sectionId}
      ></Path>
      {/* Visuals, probably text */}
      <Path
        ref={visualRef}
        className='path_non_visual'
        data={section?.identifier?.path}
        listening={false}
      ></Path>
      <InventoryInfo
        visualRef={visualRef}
        section={section}
        activeTab={activeTab}
        inventory={inventory}
      />
    </Group>
  );
}

export default Sections;
