import './zoomLevel.scss'

export default function ZoomLevel({ setScale, scale, calculateScalePercentage, stageRef }) {

    const zoomIn = () => {
        const stage = stageRef.current;
        const oldScale = stage.scaleX();

        // Get the viewport size (if applicable) or use stage size
        const stageWidth = stage.width();
        const stageHeight = stage.height();

        // Calculate the center point of the viewport/stage
        const centerX = stageWidth / 2;
        const centerY = stageHeight / 2;

        // Convert the center point to the current scale
        const centerOnStageX = (centerX - stage.x()) / oldScale;
        const centerOnStageY = (centerY - stage.y()) / oldScale;

        // Calculate the new scale
        let newScale = oldScale * 1.1;

        // Cap scale
        const wouldBeScale = calculateScalePercentage(newScale);

        if (wouldBeScale > 400 || wouldBeScale < 80) {
            newScale = oldScale;
        };

        // Calculate new position to keep the center point in the same place after scaling
        const newPos = {
            x: -(centerOnStageX * newScale - centerX),
            y: -(centerOnStageY * newScale - centerY),
        };

        // Apply the new scale and position
        stage.to({ 
            scaleX: newScale, 
            scaleY: newScale, 
            x: newPos.x, 
            y: newPos.y, 
            duration: 0.1 
        });
        // stage.to(newPos);
        stage.batchDraw();
        setScale(newScale);
    }

    const zoomOut = () => {
        const stage = stageRef.current;
        const oldScale = stage.scaleX();

        // Get the viewport size (if applicable) or use stage size
        const stageWidth = stage.width();
        const stageHeight = stage.height();

        // Calculate the center point of the viewport/stage
        const centerX = stageWidth / 2;
        const centerY = stageHeight / 2;

        // Convert the center point to the current scale
        const centerOnStageX = (centerX - stage.x()) / oldScale;
        const centerOnStageY = (centerY - stage.y()) / oldScale;

        // Calculate the new scale
        let newScale = oldScale / 1.1;

        // cap scale
        const wouldBeScale = calculateScalePercentage(newScale);

        if (wouldBeScale > 200 || wouldBeScale < 80) {
            newScale = oldScale;
        };

        // Calculate new position to keep the center point in the same place after scaling
        const newPos = {
            x: -(centerOnStageX * newScale - centerX),
            y: -(centerOnStageY * newScale - centerY),
        };

        // Apply the new scale and position
        stage.to({ 
            scaleX: newScale, 
            scaleY: newScale, 
            x: newPos.x, 
            y: newPos.y, 
            duration: 0.1 
        });
        stage.batchDraw();
        setScale(newScale);
    }

    return (
        <div className='zoomContainer'>
            <button className='zoomButton' onClick={() => zoomOut()}>-</button>
            <div>{calculateScalePercentage(scale)}%</div>
            <button className='zoomButton' onClick={() => zoomIn()}>+</button>
        </div>
    )
}