import React from "react";

import { getTimezoneDate } from "../../../utilities/helpers";

import Modal from "react-bootstrap/Modal";
import Alert from "react-bootstrap/Alert";
import Stack from "react-bootstrap/Stack";
import Button from "react-bootstrap/Button";

import { Review } from "./Review";
import { Visibility } from "./Visibility";
import { EventInfo } from "./EventInfo";
import { Spinner } from "../../LoadingContainer/Spinner";
import "./publishModal.scss"

export default function PublishModal({
  show,
  event,
  step,
  isScheduled,
  eventStart,
  eventVisibility,
  setEventVisibility,
  error,
  errorMsg,
  alert,
  isDisabled,
  isSaving,
  handleVisibility,
  handleEdit,
  handleCancel,
  handleNext,
}) {

  return (
    <Modal
      centered
      animation={false}
      fullscreen='md-down'
      show={show}
      onHide={handleCancel}
      backdrop='static'
    >
      <Modal.Body>
        <h1 className='vjx-pm-heading'>Publish event</h1>
        {alert.show && (
          <>
            <Alert variant={alert.variant} className='mb-5'>
              <p>{alert.message}</p>
            </Alert>
          </>
        )}
        {step === 1 && <Review handleEdit={handleEdit} />}
        {step === 2 && (
          <Visibility
            eventVisibility={eventVisibility}
            setEventVisibility={setEventVisibility}
            generalOnsale={
              new Date(getTimezoneDate(event?.generalOnsale, event?.timezone))
            }
            isScheduled={isScheduled}
            error={error}
            errorMsg={errorMsg}
            handleVisibility={handleVisibility}
          />
        )}
        {step === 3 && (
          <EventInfo
            event={event}
            start={eventStart}
            isScheduled={isScheduled}
          />
        )}
        <Stack className='btn-group-flex' direction='horizontal'>
          <div className='me-auto'>
            <span className='text-muted fw-medium '>
              <span className='text-body'>{step}</span> / 3
            </span>
          </div>
          <Button variant='outline-light' size='lg' onClick={handleCancel}>
            Cancel
          </Button>
          <Button
            size='lg'
            className={`btn-width-sm ${
              step !== 3 && !isSaving ? "btn-next" : ""
            }`}
            disabled={isDisabled || error || isSaving}
            onClick={handleNext}
          >
            {isSaving ? (
              <Spinner />
            ) : (
              <>
                {step == "3" ? (isScheduled ? "Schedule" : "Publish") : "Next"}
              </>
            )}
          </Button>
        </Stack>
      </Modal.Body>
    </Modal>
  );
}
