import React, { useState, useEffect } from 'react';

import { generatePredeterminedColor, generateRandomColor } from '../../../../utilities/helpers';

import Card from 'react-bootstrap/Card';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';

import { InventoryCard } from './InventoryCard';
import { WarningCard } from '../../../WarningCard';

export default function Inventory({ isEditing, offerName, openInventory, inventory, setInventory, getSeatCount, offers }) {

    const [key, setKey] = useState('open')

    const [exclusiveInventory, setExclusiveInventory] = useState()

    useEffect(() => {
        if (openInventory?.gaSeats) {
            const gaSeats = Object.keys(openInventory?.gaSeats)?.reduce((acc, key) => {
                acc[key] = [];
                return acc;
            }, {});

            const exclusiveOffers = offers.filter((offer) => offer.inventoryType !== "open");
            const colorIndex = exclusiveOffers?.length;
            setExclusiveInventory({
                color: generatePredeterminedColor(colorIndex),
                seats: [],
                gaSeats
            })
        }
    }, [openInventory])

    useEffect(() => {
        setKey(inventory?.type?.toLowerCase())
    }, [inventory])

    // switch inventory of offer between open and exclusive 
    const handleChange = (k) => {
        setKey(k)
        let data;

        // reset properties 
        if (k == 'open') {
            data = {
                ...openInventory
            }
        } else {
            data = {
                type: 'exclusive',
                color: exclusiveInventory?.color,
                seats: exclusiveInventory?.seats,
                gaSeats: exclusiveInventory?.gaSeats
            }
        }

        setInventory({ ...data })
    }

    return (
        <>
            <div className="card-body-heading card-body-heading--sm">
                <Card.Title as="h5">Inventory</Card.Title>
            </div>
            {isEditing ? (
                <InventoryCard name={key === 'open' ? 'Open' : offerName} inventory={inventory} type={key} getSeatCount={getSeatCount} />
            ) : (
                <Tabs
                    id="inventory"
                    variant="pills"
                    activeKey={key}
                    onSelect={(k) => handleChange(k)}
                    className="w-25 "
                    justify
                >
                    <Tab eventKey="open" title="Open">
                        <InventoryCard type={key} name="Open" inventory={inventory} getSeatCount={getSeatCount} />
                    </Tab>
                    <Tab eventKey="exclusive" title="Custom">
                        <InventoryCard type={key} name={offerName} inventory={inventory} getSeatCount={getSeatCount} />
                    </Tab>
                </Tabs>
            )}
            {!isEditing && (
                <WarningCard text="After creating this offer, it is not possible to change the inventory" variant="primary" margin="mt" />
            )}
        </>
    );
}
