import Button from 'react-bootstrap/Button';

export default function Sidebar() {
  return (
    <>
      <aside className='sidebar sidebar-md'>
        <div className='sidebar-wrapper sidebar-wrapper-sm'>
          <h5>Selected</h5>
          <Button size="md" className={'btn'}>Assign To Fan</Button>
        </div>
      </aside>
    </>
  )
}