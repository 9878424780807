import React, { useEffect, useState, useContext, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import LoadingContext from '../../context/Loading/Loading';
import AuthService from '../../utilities/services/auth.service';
import UserContext from '../../context/User/User';

import { getPackage, updatePackageSettings } from '../../utilities/api';

import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import Alert from 'react-bootstrap/Alert';

import { NoPermissionsContainer } from '../NoPermissionsContainer';
import { PageLoadingContainer } from '../PageLoadingContainer';

import CreatePackageButton from '../CreatePackageButton/CreatePackageButton';
import { Pricing } from './Pricing';
import { Limits } from './Limits';

export default function PackageSettingsWrapper({ uuid }) {
  const navigate = useNavigate();
  const { isLoading, showLoading, hideLoading } = useContext(LoadingContext)
  const { orgPermissions } = useContext(UserContext)
  const organization = AuthService.getOrg()[0];
  const { getPermissions } = AuthService;
  const [hasPermission, setHasPermission] = useState(true);
  const [initialState, setInitialState] = useState();
  const [showFooter, setShowFooter] = useState(false);
  const [isSaving, setSaving] = useState(false);
  const [currentPackage, setPackage] = useState();

  const packagePriceRef = useRef()
  const additionalFeesRef = useRef()
  const additionalTaxesRef = useRef()
  const maximumRef = useRef()
  const minimumRef = useRef()
  const incrementsRef = useRef()

  const loadPackage = () => {
    getPackage(uuid)
      .then((res) => { 
        setPackage(res?.data);
      })
      .catch((err) => console.error(err))
  }

  const handleSave = () => {
    setSaving(true)

    let data = {
      id: currentPackage.id,
      ticketMinimum: Number(minimumRef.current.value),
      ticketMaximum: Number(maximumRef.current.value),
      incrementsOf: Number(incrementsRef.current.value),
      packagePrice: Number(packagePriceRef.current.value),
      additionalFees: Number(additionalFeesRef.current.value),
      additionalTaxes: Number(additionalTaxesRef.current.value)
    }
        
    updatePackageSettings(data)
    .then(() => {
      setSaving(false)
      navigate(`/package/${uuid}/package-seatmap`)
    })
  }

  const handleGoBack = () => {
    console.log('Back')
  }

  const validateForm = () => {
    if (packagePriceRef.current.value && minimumRef.current.value && maximumRef.current.value && incrementsRef.current.value) {
      setShowFooter(true)
    } else {
      setShowFooter(false)
    }
  }

  useEffect(() => {
    loadPackage()
  }, [uuid])

  return (
    <>
            {isLoading ? (
                <PageLoadingContainer />
            ) : (
                <div className='position-relative'>
                    <section className={`wrapper package-form ${!hasPermission ? 'overlay' : ''}`}>
                        {alert.show &&
                            <>
                                <Alert variant={alert.variant} className="mb-5">
                                    <p>{alert.message}</p>
                                </Alert>
                            </>
                        }
                        <header className="section-header-sm section-heading section-heading--secondary">
                            <h1>Package Settings</h1>
                        </header>
                        <Form onSubmit={handleSave}>
                            <Card body className='card--md'>
                              <Pricing validateForm={validateForm} packagePriceRef={packagePriceRef} additionalFeesRef={additionalFeesRef} additionalTaxesRef={additionalTaxesRef} currentPackage={currentPackage}  />
                            </Card>
                            <Card body className='card--md'>
                              <Limits maximumRef={maximumRef} minimumRef={minimumRef} incrementsRef={incrementsRef} validateForm={validateForm} currentPackage={currentPackage}  />
                            </Card>
                        </Form>
                    </section>

                    {showFooter && (
                        <CreatePackageButton
                          isEditing={true} 
                          isSaving={isSaving} 
                          showGoBack={false}
                          handleSave={handleSave}
                          handleGoBack={handleGoBack}
                        />
                    )}

                    {!hasPermission && (
                        <NoPermissionsContainer />
                    )}
                </div>
            )
            }
    </>
  )
}