import React from "react"
import Form from 'react-bootstrap/Form';

export default function EventsList({ events, updateEvents, currentPackage, checkboxIds }) {

  const isChecked = (eventId) => {
    if (currentPackage && currentPackage.events.length > 0) {
      let hasId = currentPackage.events.find((event) => event.id === Number(eventId))
      if (hasId) return true
    } else { 
      return checkboxIds.includes(eventId.toString())
    }
  }
  return (
    <>
      <div style={{maxHeight: '250px'}} className="overflow-auto"> 
          { events && events.length > 0 && events.map((event, index) => 
            <Form.Check
              type={'checkbox'}
              className="event-checkbox"
              id={`${event.id}`}
              label={`${event.name}`}
              data-event-id={event.id}
              key={index}
              checked={!!isChecked(event.id)}
              onChange={(e) => updateEvents()}
            />
          )}
    </div>
    </>
  )
}