import React, { useEffect } from 'react';
import EventImage from '../EventImage/EventImage';
import Form from 'react-bootstrap/Form';


import { formatAddress } from '../../../utilities/helpers';

export default function Details({ nameRef, descriptionRef, formValid, currentPackage = {}, venues, room, setRoom, setSeatmap, seatmap, setTimezone, venue, setVenue, hideConfig, coordinates, setCoordinates, image, setImage }) {
 
    const handleVenue = (e) => {
        const uuid = e.target.value
        const timezone = venues?.find(venue => venue.rooms.find(room => room.uuid === uuid))?.timezone
        const venue = venues?.find(venue => venue.rooms.find(room => room.uuid === uuid))
        setRoom(venue.rooms.find(room => room.uuid === uuid))
        setTimezone(timezone)
        setVenue(venue)
    }

    const handleConfiguration = (e) => {
        const seatmapId = e.target.value
        setSeatmap(seatmapId)
    }

    useEffect(() => {
    }, [room])

    return (
        <>
        { !hideConfig &&
            <>
            <Form.Group className="form-group" controlId="venue">
                <div className="form-label--flex">
                    <Form.Label>Venue & Room</Form.Label>
                </div>
                <Form.Select
                    name="venue"
                    onChange={handleVenue}
                    autoFocus
                >
                    <option disabled hidden value="">Select venue</option>
                    {venues && venues.map((option, index) => (
                        <optgroup label={`${option?.name} - ${formatAddress(option)}`} key={index}>
                            {option.rooms?.map((room, i) => (
                                <option value={room.uuid} data-venue={option} key={i}>{room.name}</option>
                            ))}
                        </optgroup>
                    ))}
                </Form.Select>
            </Form.Group>
            <Form.Group className="form-group" controlId="configuration">
                <div className="form-label--flex">
                    <Form.Label>Configuration</Form.Label>
                </div>
                <Form.Select
                    name="configuration"
                    onChange={handleConfiguration}
                >
                    <option disabled={!room} hidden value="">Select configuration</option>
                    {room && room.seatmaps.map((option, index) => (
                        <option value={option.id} key={index}>{option.name}</option>
                    ))}
                </Form.Select>
            </Form.Group>
            </>
        }
            
            <Form.Group className="form-group" controlId="venue">
                <div className="form-label--flex">
                    <Form.Label>Name of Package</Form.Label>
                </div>
                <Form.Control
                    type="text"
                    name="name"
                    ref={nameRef}
                    onChange={formValid}
                    autoFocus
                    defaultValue={currentPackage ? currentPackage?.name : '' }
                />
                    
            </Form.Group>
            <Form.Group className="form-group" controlId="name">
                <div className="form-label--flex">
                    <Form.Label>Description of Package</Form.Label>
                </div>
                <Form.Control
                    as="textarea"
                    name="description"
                    onChange={formValid}
                    ref={descriptionRef}
                    defaultValue={currentPackage ? currentPackage?.description : '' }
                />
            </Form.Group>
            <Form.Group className="form-group" controlId="thumbnail">
                <EventImage eventName={currentPackage?.name} image={image} setImage={setImage} isDisabled={false} requiredFieldErrorStatus={''} />
            </Form.Group>
        </>
    );
}
