import React from 'react';

import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';

import { RequiredText } from '../../RequiredText';

export default function Pricing({ currentPackage, handleChange, isEditable, requiredFieldErrorStatus, validateForm, additionalTaxesRef, additionalFeesRef, packagePriceRef }) {
  return (
    <>
            <div className="card-body-heading card-body-heading--flex">
                <Card.Title as="h5">Pricing</Card.Title>
            </div>
            <fieldset className='form-group'>
                <legend className='md'>
                    <p className='small'>Ticket Package Price</p>
                    <small className='subtitle'>This value will be the price for this package</small>
                </legend>
                <InputGroup className={`w-25 ${requiredFieldErrorStatus?.fees ? 'input-group-error' : ''}`}>
                    <InputGroup.Text id="facilityFee">$</InputGroup.Text>
                    <Form.Control
                        placeholder="0.00"
                        id="packagePrice"
                        name="packagePrice"
                        aria-describedby="packagePrice"
                        pattern="^[0-9.]*$"
                        disabled={isEditable}
                        onChange={validateForm}
                        ref={packagePriceRef}
                        defaultValue={currentPackage ? currentPackage?.packagePrice : ''}
                        required
                    />
                </InputGroup>
            </fieldset>
            <fieldset className='form-group'>
                <legend className='md'>
                    <p className='small'>Additional Fees</p>
                    <small className='subtitle'>This value will be additional fees added to the price for this package</small>
                </legend>
                <InputGroup className={`w-25 ${requiredFieldErrorStatus?.fees ? 'input-group-error' : ''}`}>
                    <InputGroup.Text id="facilityFee">$</InputGroup.Text>
                    <Form.Control
                        placeholder="0.00"
                        id="additionalFees"
                        name="additionalFees"
                        aria-describedby="additionalFees"
                        pattern="^[0-9.]*$"
                        disabled={isEditable}
                        onChange={validateForm}
                        defaultValue={currentPackage ? currentPackage?.additionalFees : ''}
                        ref={additionalFeesRef}
                    />
                </InputGroup>
            </fieldset>
            <fieldset className='form-group'>
                <legend className='md'>
                    <p className='small'>Additional Taxes</p>
                    <small className='subtitle'>This value will be additional taxes applied for this package</small>
                </legend>
                <InputGroup className={`w-25 ${requiredFieldErrorStatus?.fees ? 'input-group-error' : ''}`}>
                    <InputGroup.Text id="facilityFee">$</InputGroup.Text>
                    <Form.Control
                        placeholder="0.00"
                        id="additionalTaxes"
                        name="additionalTaxes"
                        aria-describedby="additionalTaxes"
                        pattern="^[0-9.]*$"
                        disabled={isEditable}
                        onChange={validateForm}
                        defaultValue={currentPackage ? currentPackage?.additionalTaxes : ''}
                        ref={additionalTaxesRef}
                    />
                </InputGroup>
            </fieldset>
        </>
  )
}