import React, { useEffect, useState, useContext, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

import LoadingContext from '../../context/Loading/Loading';
import AuthService from '../../utilities/services/auth.service';
import UserContext from '../../context/User/User';

import { checkPermission } from '../../utilities/helpers';
import { getPackage, getPackageEvents, updatePackageDetails, getOrgVenues } from '../../utilities/api';

import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import Alert from 'react-bootstrap/Alert';

import { NoPermissionsContainer } from '../NoPermissionsContainer';
import { PageLoadingContainer } from '../PageLoadingContainer';

import { Details } from '../PackagesWrapper/Details';
import { EventsList } from './EventsList';
import CreatePackageButton from '../CreatePackageButton/CreatePackageButton';
import DateTime from './DateTime/DateTime';

export default function PackageDetailsWrapper({ uuid }) {
  const navigate = useNavigate();
  const { isLoading, showLoading, hideLoading } = useContext(LoadingContext)
  const { orgPermissions } = useContext(UserContext)
  const organization = AuthService.getOrg()[0];
  const { getPermissions } = AuthService;
  const [hasPermission, setHasPermission] = useState(true);
  const [initialState, setInitialState] = useState();
  const [showFooter, setShowFooter] = useState(false);
  const [currentPackage, setPackage] = useState()
  const [events, setEvents] = useState()
  const [selectedEvents, setSelectedEvents] = useState([])
  const nameRef = useRef()
  const descriptionRef = useRef()
  const [isSaving, setSaving] = useState(false)
  const [packageStart, setPackageStart] = useState()
  const [packageEnd, setPackageEnd] = useState()
  const [venues, setVenues] = useState([])
	const [room, setRoom] = useState()
	const [seatmap, setSeatmap] = useState()
	const [timezone, setTimezone] = useState()
	const [venue, setVenue] = useState([])
  const [image, setImage] = useState()
  const [coordinates, setCoordinates] = useState()
  const hideConfig = true
  const [checkboxIds, setCheckboxIds] = useState([])

  const loadVenues = () => {
    getOrgVenues()
      .then((res) => setVenues(res?.data))
			.catch((err) => console.error(err))
  }

  const loadEvents = () => {
   getPackageEvents(uuid)
    .then((res) => {setEvents(res?.data); console.log(res.data)})  
    .catch((err) => console.error(err))
  }

  const loadPackage = () => {
    getPackage(uuid)
      .then((res) => {
        setPackage(res?.data); 
        setSelectedEvents(res.data?.events?.data?.map((event) => event.id));
        setPackageStart(new Date(res.data?.salesStart))
        setPackageEnd(new Date(res.data?.salesEnd))
      })
      .then(loadEvents())
      .then(loadVenues())
      .catch((err) => console.error(err))
    
  }

  const handleSave = () => {
    setSaving(true)

    let data = {
      id: currentPackage.id,
      name: nameRef.current.value,
      description: descriptionRef.current.value,
      events: checkboxIds,
      salesStart: packageStart,
      salesEnd: packageEnd
    }

    updatePackageDetails(data)
      .then((res) => { 
        setSaving(false) 
        navigate(`/package/${uuid}/package-settings`)
      })
      .catch((err) => console.error(err))

  }

  const handleGoBack = () => {
    console.log('Back')
  }

  const formValid = () => {
    if (nameRef.current.value && nameRef.current.value.split('').length >= 3 && checkboxIds && checkboxIds.length > 0 && packageStart && packageEnd) {
      return setShowFooter(true)
    } 
    setShowFooter(false)
  }

  function updateEvents() {
    const checkboxes = document.querySelectorAll('.form-check-input')

    for (let checkbox of checkboxes) {
      let eventId = checkbox.dataset.eventId;

      if (checkbox.checked) {
        let ids = [...checkboxIds, eventId]
        // removes duplicate ids
        ids = [...new Set(ids)]
        setCheckboxIds(ids)
      } else {
        let index = checkboxIds.indexOf(eventId)
        if (index > -1) setCheckboxIds((checkboxIds) => checkboxIds.filter(e => e !== eventId))
      }
    }
  }

  useEffect(() => {
    loadPackage()
  }, [uuid])

  useEffect(() => {
     formValid()
     console.log('selectedEvents ', checkboxIds)
  }, [checkboxIds])

  return (
    <>
            {isLoading ? (
                <PageLoadingContainer />
            ) : (
                <div className='position-relative'>
                    <section className={`wrapper package-form ${!hasPermission ? 'overlay' : ''}`}>
                        {alert.show &&
                            <>
                                <Alert variant={alert.variant} className="mb-5">
                                    <p>{alert.message}</p>
                                </Alert>
                            </>
                        }
                        <header className="section-header-sm section-heading section-heading--secondary">
                            <h1>Package Details</h1>
                        </header>
                        <Form onSubmit={handleSave}>
                            <Card body className='card--md'>
                                <Details currentPackage={currentPackage} nameRef={nameRef} descriptionRef={descriptionRef} formValid={formValid} venues={venues} setRoom={setRoom} room={room} setSeatmap={setSeatmap} seatmap={seatmap} setTimezone={setTimezone} venue={venue} setVenue={setVenue} hideConfig={hideConfig} coordinates={coordinates} setCoordinates={setCoordinates} image={image} setImage={setImage} />
                            </Card>
                            <Card body className='card--md'>
                                <DateTime packageStart={packageStart} packageEnd={packageEnd} setPackageStart={setPackageStart} setPackageEnd={setPackageEnd} formValid={formValid} />
                            </Card>
                            <Card body className='card--md'>
                                <EventsList events={events} updateEvents={updateEvents} currentPackage={currentPackage} checkboxIds={checkboxIds} />
                            </Card>
                        </Form>
                    </section>

                    {showFooter && (
                        <CreatePackageButton
                          isEditing={true} 
                          isSaving={isSaving} 
                          showGoBack={false}
                          handleSave={handleSave}
                          handleGoBack={handleGoBack}
                        />
                    )}

                    {!hasPermission && (
                        <NoPermissionsContainer />
                    )}
                </div>
            )
            }
    </>
  )
}