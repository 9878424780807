import React from "react";

import Image from "react-bootstrap/Image";
import Stack from "react-bootstrap/Stack";
import Button from "react-bootstrap/Button";

import map from "../../../../assets/map-badge.svg";

export default function Review({ handleEdit }) {
  return (
    <Stack gap={3}>
      <div className='mb-3'>
        <h2 className='vjx-pm-subheading'>Review</h2>
        <small className='vjx-pm-paragraph'>
          Review your seatmap before publishing the event
        </small>
      </div>
      <Stack gap={4} className='align-items-center'>
        <Image src={map} width='301' height='151' alt='Event map' />
        <Button
          variant='link'
          className='icon-button icon-button-md icon-button--link btn-edit gap-2'
          onClick={handleEdit}
        >
          Edit map
        </Button>
      </Stack>
    </Stack>
  );
}
