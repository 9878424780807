import { useLayoutEffect, useState } from "react";

import { generatePredeterminedColor, generateRandomColor } from "../../../../utilities/helpers";

import { ActionsProvider } from "./ActionsProvider/ActionsProvider";
import { useInventory } from "../../InventoryProvider/InventoryProvider";
import { Sidebar } from "./Sidebar";
import { SeatMap } from "./SeatMap";

import "./mapConfigurations.scss";

export default function MapConfigurations({
  isEventOnsale,
  hasEventEnded,
  isEventSoldout,
  data,
  background,
  activeTab,
  priceLevels,
  setPriceLevels,
  unassignedSeats,
  getDefaultObj,
  getObjectTotalSeatCount,
}) {
  const {
    setHolds,
    setKills,
    HOLDSCOLOR,
    KILLSCOLOR,
    selectedSeatIds,
    setSelectedSeatIds,
    getSeatItem,
    getGAPLIDFrom,
  } = useInventory();

  const [selectedGASectionId, setSelectedGASectionId] = useState(null);
  const [showSelectGASeats, setShowSelectGASeats] = useState(false);

  // modify sidebar container when seats are selected
  useLayoutEffect(() => {
    const el = document.getElementById("map");
    if (
      (activeTab === "scaling" && Object.keys(selectedSeatIds).length > 0) ||
      (activeTab === "inventory" && Object.keys(selectedSeatIds).length > 0)
    ) {
      el.classList.add("panel-open");
    }

    return () => {
      el.classList.remove("panel-open");
    };
  }, [selectedSeatIds]);

  // add new pricing level, hold, or kill object
  const handleAdd = (type, id) => {
    const generateKey = (obj) => {
      const keys = Object.keys(obj);
      if (keys.length === 0) {
        return keys.length + 1;
      } else {
        // Get the highest existing key and add 1 to get the next key - so keys don't get messed up after deleting
        return Math.max(...keys) + 1;
      }
    };

    const createHoldKill = (prevState, id, color, name) => {
      const index = generateKey(prevState);

      // adding sub category
      if (id) {
        const catIndex = generateKey(prevState[id]?.categories);
        return {
          ...prevState,
          [id]: {
            ...prevState[id],
            categories: {
              ...prevState[id].categories,
              [catIndex]: {
                ...getDefaultObj(catIndex, `Category ${catIndex}`, color),
              },
            },
          },
        };
      } else {
        return {
          ...prevState,
          [index]: {
            ...getDefaultObj(index, `${index} ${name}`, color),
            categories: {},
          },
        };
      }
    };

    switch (type) {
      case "level":
        const index = generateKey(priceLevels);
        console.log("----")
        console.log("handleAdd: PL_LENGTH", index)
        console.log("----")
        setPriceLevels((prevState) => ({
          ...prevState,
          [index]: {
            ...getDefaultObj(index, `P${index}`, generatePredeterminedColor(index)),
            price: 0,
          },
        }));
        break;

      case "hold":
        setHolds((prevState) =>
          createHoldKill(prevState, id, HOLDSCOLOR, "Hold")
        );
        break;

      case "kill":
        setKills((prevState) =>
          createHoldKill(prevState, id, KILLSCOLOR, "Kill")
        );
        break;

      default:
        break;
    }
  };

  const selectUnassigned = () => {
    console.log("selectUnassigned unassignedSeats: ", unassignedSeats);
    const unassignedObject = Object.values(unassignedSeats)[0];
    const unassignedIds = [];

    // Get all regular unassigned seats
    unassignedIds.push(...unassignedObject.seats);

    // Select regular unassigned seats
    selectRegularSeats(null, unassignedIds);

    if (unassignedObject.gaSeats) {
      console.log("unassignedObject.gaSeats", unassignedObject.gaSeats);
      const addToGASeats = {};

      for (const [sectionId, seats] of Object.entries(
        unassignedObject.gaSeats
      )) {
        addToGASeats[sectionId] = {
          unassigned: seats,
        };
      }

      // Select GA unassigned seats
      selectGASeats(addToGASeats);
    }
  };

  // GA section is clicked, do something
  const clickGASection = (section) => {
    if (activeTab === "inventory") {
      clickOpenGAModal(section);
      return;
    }

    // If seats are currently in a PL return the id
    const sectionPLRes = getGAPLIDFrom(section.sectionId);
    // Also check if section is already selected, if so return

    const fromIdKey = sectionPLRes || "unassigned";
    const addToGASeats = {
      [section.sectionId]: {
        [fromIdKey]: section.spots,
      },
    };

    selectGASeats(addToGASeats);
  };

  const selectGASeats = (gaSeatsToSelect) => {
    console.log("selectGASeats: ", gaSeatsToSelect);
    setSelectedSeatIds((prevSelected) => {
      const newSelected = { ...prevSelected };
      if (!newSelected.gaSeats) {
        newSelected.gaSeats = {};
      }

      for (const [sectionId, fromSeats] of Object.entries(gaSeatsToSelect)) {
        // Add object for sectionId if does not exist
        if (!newSelected.gaSeats[sectionId]) {
          newSelected.gaSeats[sectionId] = {};
        }

        for (const [fromId, seats] of Object.entries(fromSeats)) {
          if (!newSelected.gaSeats[sectionId][fromId]) {
            newSelected.gaSeats[sectionId][fromId] = [];
          }

          // Filter out seats that already exist before pushing
          const existingSeats = new Set(newSelected.gaSeats[sectionId][fromId]);
          const uniqueNewSeats = seats.filter(
            (seat) => !existingSeats.has(seat)
          );

          newSelected.gaSeats[sectionId][fromId].push(...uniqueNewSeats);
        }
      }

      return newSelected;
    });
  };

  // save regular seated seats,,,
  const selectRegularSeats = (seatId, seatIdArray) => {
    setSelectedSeatIds((prevSelected) => {
      const newSelected = { ...prevSelected };
      if (!newSelected.seats) {
        newSelected.seats = {};
      }

      const addSeat = (id) => {
        const seatItem = getSeatItem(id);
        const key = seatItem && seatItem.key ? seatItem.key : "unassigned";

        if (!(seatItem?.name?.toLowerCase() === "sold")) {
          if (!newSelected.seats[key]) {
            newSelected.seats[key] = [];
          }
          if (!newSelected.seats[key].includes(id)) {
            newSelected.seats[key].push(id);
          }
        }
      };

      if (seatId) {
        // Handle single seat selection
        addSeat(seatId);
      } else if (seatIdArray) {
        // Handle multiple seat selection
        seatIdArray.forEach(addSeat);
      }

      return newSelected;
    });
  };

  const clickOpenGAModal = (section) => {
    setSelectedGASectionId(section?.sectionId);
    setShowSelectGASeats(true);
  };

  return (
    <>
      <Sidebar
        tab={activeTab}
        handleAdd={handleAdd}
        isEventOnsale={isEventOnsale}
        hasEventEnded={hasEventEnded}
        isEventSoldout={isEventSoldout}
        showSelectGASeats={showSelectGASeats}
        setShowSelectGASeats={setShowSelectGASeats}
        selectedGASectionId={selectedGASectionId}
        selectUnassigned={selectUnassigned}
        selectGASeats={selectGASeats}
        getObjectTotalSeatCount={getObjectTotalSeatCount}
      />
      <ActionsProvider>
        <SeatMap
          data={data}
          activeTab={activeTab}
          selectRegularSeats={selectRegularSeats}
          clickGASection={clickGASection}
          background={background}
          clickOpenGAModal={clickOpenGAModal}
        />
      </ActionsProvider>
    </>
  );
}
