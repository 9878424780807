import { useParams } from "react-router-dom";
import { AssignPackagesWrapper } from "../../components"

export default function AssignPackagesPage() {
  const { uuid } = useParams()
  return (
    <>
      <AssignPackagesWrapper uuid={uuid} />
    </>
  )
}