import React from "react";

import { formatDateTime, getTimezoneDate } from "../../../../utilities/helpers";

import Stack from "react-bootstrap/Stack";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Button from "react-bootstrap/Button";

import "./navigation.scss";

export default function Navigation({
    event,
    isEventPublished,
    hasEventEnded,
    isSaved,
    configuration,
    templateName,
    activeTab,
    setActiveTab,
    unassignedSeats,
    handleShowResetConfiguration,
    handleShowSave,
    handleLeave,
}) {
    const handleClick = () => {
        setActiveTab("inventory");
    };

    return (
        <div className='banner banner--seatmap'>
            <div className='banner-wrapper'>
                <Stack direction='horizontal' gap={3} className='event-details'>
                    <Button
                        variant='default'
                        size='sm'
                        className='btn-icon flex-shrink-0 border-0 w-auto'
                        onClick={handleLeave}
                    >
                        <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='24'
                            height='24'
                            role='img'
                            viewBox='0 0 24 24'
                            fill='none'
                        >
                            <path
                                fillRule='evenodd'
                                clipRule='evenodd'
                                d='M15.7071 19.2071C16.0976 18.8166 16.0976 18.1834 15.7071 17.7929L9.91421 12L15.7071 6.20711C16.0976 5.81658 16.0976 5.18342 15.7071 4.79289C15.3166 4.40237 14.6834 4.40237 14.2929 4.79289L8.5 10.5858C7.71895 11.3668 7.71895 12.6332 8.5 13.4142L14.2929 19.2071C14.6834 19.5976 15.3166 19.5976 15.7071 19.2071Z'
                                fill='#777E91'
                            />
                        </svg>
                    </Button>
                    <Stack gap={1} className='event-details'>
                        <h1 className='normal m-0 event-title'>{event?.name}</h1>
                        <span className='subtitle subtitle--dark m-0'>
                            <small>
                                {formatDateTime(
                                    getTimezoneDate(event?.start, event?.timezone),
                                    "dateOnly"
                                )}
                            </small>
                            <small className='time'>
                                {formatDateTime(
                                    getTimezoneDate(event?.start, event?.timezone),
                                    "timeOnly"
                                )}
                            </small>
                        </span>
                    </Stack>
                </Stack>
                <Stack
                    direction='horizontal'
                    as='ul'
                    className='gap-5 tabs small flex-grow-1 justify-content-center'
                >
                    <li className={`tab ${activeTab === "scaling" ? "active" : ""}`}>
                        <Button
                            variant='link'
                            className='text-reset'
                            onClick={() => setActiveTab("scaling")}
                        >
                            Scaling
                        </Button>
                    </li>
                    <li className={`tab ${activeTab === "inventory" ? "active" : ""}`}>
                        <Button
                            variant='link'
                            className={`text-reset ${unassignedSeats && unassignedSeats?.seats.length > 0
                                ? "btn-link-disabled btn-link-disabled--with-cursor"
                                : ""
                                }`}
                            {...(unassignedSeats && unassignedSeats?.seats.length === 0 && {
                                onClick: handleClick,
                            })}
                        >
                            Inventory
                        </Button>
                    </li>
                </Stack>
                <Stack
                    direction='horizontal'
                    className='flex-grow-1 justify-content-end'
                >
                    <Stack direction='horizontal' gap={3} className='position-relative'>
                        {isSaved && (
                            <div id='saved'>
                                <small className='text-muted'>Saved</small>
                            </div>
                        )}
                        <OverlayTrigger
                            placement='bottom'
                            overlay={
                                <Tooltip className='tooltip-lg'>
                                    <Stack gap={1}>
                                        <span className='fw-bold'>{configuration?.name}</span>
                                        {templateName && <span>Template: {templateName}</span>}
                                    </Stack>
                                </Tooltip>
                            }
                        >
                            <Button
                                variant='outline-light'
                                className='btn-icon'
                                aria-label='Map Information'
                            >
                                <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    width='20'
                                    height='20'
                                    viewBox='0 0 20 20'
                                    fill='none'
                                >
                                    <path
                                        fillRule='evenodd'
                                        clipRule='evenodd'
                                        d='M10.0013 16.668C13.6832 16.668 16.668 13.6832 16.668 10.0013C16.668 6.3194 13.6832 3.33464 10.0013 3.33464C6.3194 3.33464 3.33464 6.3194 3.33464 10.0013C3.33464 13.6832 6.3194 16.668 10.0013 16.668ZM10.0013 18.3346C14.6037 18.3346 18.3346 14.6037 18.3346 10.0013C18.3346 5.39893 14.6037 1.66797 10.0013 1.66797C5.39893 1.66797 1.66797 5.39893 1.66797 10.0013C1.66797 14.6037 5.39893 18.3346 10.0013 18.3346Z'
                                        fill='#777E91'
                                    />
                                    <path
                                        fillRule='evenodd'
                                        clipRule='evenodd'
                                        d='M10.0013 8.33203C10.4615 8.33203 10.8346 8.70513 10.8346 9.16536V13.3328C10.8346 13.793 10.4615 14.1661 10.0013 14.1661C9.54106 14.1661 9.16797 13.793 9.16797 13.3328V9.16536C9.16797 8.70513 9.54106 8.33203 10.0013 8.33203Z'
                                        fill='#777E91'
                                    />
                                    <circle
                                        cx='10.0013'
                                        cy='6.66536'
                                        r='0.833333'
                                        fill='#777E91'
                                    />
                                </svg>
                            </Button>
                        </OverlayTrigger>
                        <Button
                            variant='outline-light'
                            className='btn-icon'
                            aria-label='Reset'
                            onClick={handleShowResetConfiguration}
                            disabled={isEventPublished || hasEventEnded}
                        >
                            <svg
                                role='img'
                                title='Refresh'
                                width='20'
                                height='20'
                                viewBox='0 0 20 20'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                            >
                                <path
                                    d='M3.33329 10.0003C3.33329 6.31843 6.31806 3.33366 9.99996 3.33366C12.0975 3.33366 13.9406 4.30324 15.1518 5.828L13.7524 5.8239C13.2922 5.82255 12.918 6.19455 12.9166 6.65478C12.9153 7.11502 13.2873 7.48921 13.7475 7.49056L16.6166 7.49896C16.6486 7.50092 16.6808 7.50103 16.7131 7.49924L17.0809 7.50032C17.3023 7.50097 17.5149 7.41346 17.6717 7.25711C17.8285 7.10076 17.9166 6.88843 17.9166 6.66699L17.9166 3.33366C17.9166 2.87342 17.5435 2.50033 17.0833 2.50033C16.6231 2.50033 16.25 2.87342 16.25 3.33366L16.25 4.54155C14.7395 2.78397 12.5191 1.66699 9.99996 1.66699C5.39759 1.66699 1.66663 5.39795 1.66663 10.0003C1.66663 10.4606 2.03972 10.8337 2.49996 10.8337C2.9602 10.8337 3.33329 10.4606 3.33329 10.0003Z'
                                    fill='#777E91'
                                />
                                <path
                                    d='M16.6667 10.0003C16.6667 13.6822 13.6819 16.667 10 16.667C7.9025 16.667 6.05936 15.6974 4.84817 14.1727L6.2476 14.1768C6.70783 14.1781 7.08202 13.8061 7.08337 13.3459C7.08472 12.8856 6.71272 12.5114 6.25248 12.5101L3.38343 12.5017C3.35142 12.4997 3.31921 12.4996 3.28694 12.5014L2.91915 12.5003C2.69771 12.4997 2.48512 12.5872 2.32832 12.7435C2.17151 12.8999 2.08337 13.1122 2.08337 13.3337L2.08338 16.667C2.08338 17.1272 2.45647 17.5003 2.91671 17.5003C3.37695 17.5003 3.75004 17.1272 3.75004 16.667L3.75004 15.4591C5.2605 17.2167 7.48089 18.3337 10 18.3337C14.6024 18.3337 18.3334 14.6027 18.3334 10.0003C18.3334 9.54009 17.9603 9.16699 17.5 9.16699C17.0398 9.16699 16.6667 9.54009 16.6667 10.0003Z'
                                    fill='#777E91'
                                />
                            </svg>
                        </Button>
                        {/* <Button
                            variant='outline-light'
                            className='btn-icon'
                            aria-label='Save'
                            onClick={handleShowSave}
                        >
                            <svg
                                role='img'
                                title='Save'
                                width='20'
                                height='20'
                                viewBox='0 0 20 20'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                            >
                                <path
                                    fillRule='evenodd'
                                    clipRule='evenodd'
                                    d='M13.1311 1.66699C13.7941 1.66699 14.43 1.93038 14.8989 2.39923L17.6011 5.10143C18.0699 5.57027 18.3333 6.20615 18.3333 6.86919V15.8337C18.3333 17.2144 17.214 18.3337 15.8333 18.3337H4.16663C2.78591 18.3337 1.66663 17.2144 1.66663 15.8337V4.16699C1.66663 2.78628 2.78591 1.66699 4.16663 1.66699H13.1311ZM4.16663 16.667H4.99996V13.3337C4.99996 12.4132 5.74615 11.667 6.66663 11.667H13.3333C14.2538 11.667 15 12.4132 15 13.3337V16.667H15.8333C16.2935 16.667 16.6666 16.2939 16.6666 15.8337V6.86919C16.6666 6.64818 16.5788 6.43622 16.4225 6.27994L14.1666 4.02401V5.83366C14.1666 6.75413 13.4204 7.50033 12.5 7.50033H7.49996C6.57948 7.50033 5.83329 6.75413 5.83329 5.83366V3.33366L4.16663 3.33366C3.70639 3.33366 3.33329 3.70675 3.33329 4.16699V15.8337C3.33329 16.2939 3.70639 16.667 4.16663 16.667ZM7.49996 3.33366V5.83366H12.5V3.35856C12.4344 3.34215 12.3665 3.33366 12.2978 3.33366L7.49996 3.33366ZM13.3333 16.667H6.66663V13.3337H13.3333V16.667Z'
                                    fill='#777E91'
                                />
                            </svg>
                        </Button> */}
                    </Stack>
                </Stack>
            </div>
        </div>
    );
}
