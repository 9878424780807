import React, { useEffect, useState, useContext, useRef, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import LoadingContext from '../../context/Loading/Loading';
import AuthService from '../../utilities/services/auth.service';
import UserContext from '../../context/User/User';

import { checkPermission } from '../../utilities/helpers';
import { getPackage, updateSeatsInPackage } from '../../utilities/api';

import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import Alert from 'react-bootstrap/Alert';

import { SeatMap } from './SeatMap';
import { NoPermissionsContainer } from '../NoPermissionsContainer';
import { PageLoadingContainer } from '../PageLoadingContainer';
import { PackageActionsProvider } from './PackageActionsProvider/ActionsProvider';
import CreatePackageButton from '../CreatePackageButton/CreatePackageButton';

export default function PackageSeatmapWrapper({ uuid }) {
  const navigate = useNavigate();
  const { isLoading, showLoading, hideLoading } = useContext(LoadingContext)
  const { orgPermissions } = useContext(UserContext)
  const organization = AuthService.getOrg()[0];
  const { getPermissions } = AuthService;
  const [hasPermission, setHasPermission] = useState(true);
  const [isSaving, setSaving] = useState(false)
  const [showFooter, setShowFooter] = useState(false);
  const [currentPackage, setPackage] = useState()
  const [seatmap, setSeatmap] = useState()
  const [data, setData] = useState({});
  const [selectedSeatIds, setSelectedSeatIds] = useState([])
  const [showSelectGASeats, setShowSelectGASeats] = useState(false);
  const [selectedGASectionId, setSelectedGASectionId] = useState(null)
  const activeTab = "packages"

  const loadPackage = () => {
    getPackage(uuid)
      .then((res) => {
        setPackage(res?.data);
        setSeatmap(res?.data.seatmap);
        setSelectedSeatIds(res?.data.seatsInPackage)
      })
      .catch((err) => console.error(err))
  }

  useEffect(() => {
    loadPackage()
  }, [])

  const handleSave = () => {
    setSaving(true)

    let data = {
      id: currentPackage.id,
      seatsInPackage: selectedSeatIds
    }
        
    updateSeatsInPackage(data)
    .then(() => {
      setSaving(false)
      navigate(`/package/${uuid}/assign-packages`)
    })
  }

  const handleGoBack = () => {
    console.log('Go Back ...')
  }

  const getSeatItem = (seatId) => {
    // Check if selected
    let seatItem;

    seatItem = {
        ...seatItem,
        color: "rgba(20, 20, 22, 1)",
        selected: true,
      };

    return seatItem;
  };

  const selectRegularSeats = (seatId, seatIdArray) => {
    setSelectedSeatIds((prevSelected) => {
      const newSelected = { ...prevSelected };
      if (!newSelected.seats) {
        newSelected.seats = {};
      }

      const addSeat = (id) => {
        const seatItem = getSeatItem(id);
        const key = seatItem && seatItem.key ? seatItem.key : "selected";

        if (!(seatItem?.name?.toLowerCase() === "sold")) {
          if (!newSelected.seats[key]) {
            newSelected.seats[key] = [];
          }
          if (!newSelected.seats[key].includes(id)) {
            newSelected.seats[key].push(id);
          }
        }
      };

      if (seatId) {
        // Handle single seat selection
        addSeat(seatId);
      } else if (seatIdArray) {
        // Handle multiple seat selection
        seatIdArray.forEach(addSeat);
      }

      return newSelected;
    });
  };

  const clickGASection = (section) => {
    if (activeTab === "inventory") {
      clickOpenGAModal(section);
      return;
    }

    // Also check if section is already selected, if so return

    const fromIdKey = "unassigned";
    const addToGASeats = {
      [section.sectionId]: {
        [fromIdKey]: section.spots,
      },
    };

    selectGASeats(addToGASeats);
  };

  const selectGASeats = (gaSeatsToSelect) => {
    setSelectedSeatIds((prevSelected) => {
      const newSelected = { ...prevSelected };
      if (!newSelected.gaSeats) {
        newSelected.gaSeats = {};
      }

      for (const [sectionId, fromSeats] of Object.entries(gaSeatsToSelect)) {
        // Add object for sectionId if does not exist
        if (!newSelected.gaSeats[sectionId]) {
          newSelected.gaSeats[sectionId] = {};
        }

        for (const [fromId, seats] of Object.entries(fromSeats)) {
          if (!newSelected.gaSeats[sectionId][fromId]) {
            newSelected.gaSeats[sectionId][fromId] = [];
          }

          // Filter out seats that already exist before pushing
          const existingSeats = new Set(newSelected.gaSeats[sectionId][fromId]);
          const uniqueNewSeats = seats.filter(
            (seat) => !existingSeats.has(seat)
          );

          newSelected.gaSeats[sectionId][fromId].push(...uniqueNewSeats);
        }
      }
      return newSelected;
    });
  };

  const clickOpenGAModal = (section) => {
    setSelectedGASectionId(section?.sectionId);
    setShowSelectGASeats(true);
  };

  useEffect(() => {
    if (selectedSeatIds && (selectedSeatIds?.seats || selectedSeatIds?.gaSeats)) {
      setShowFooter(true)
    } else {
      setShowFooter(false)
    }
  }, [selectedSeatIds])

  useEffect(() => {
    // console.log('data ', data)
  }, [data])


  return (
    <>
            {isLoading ? (
                <PageLoadingContainer />
            ) : (
                <div className='position-relative'>
                    <section className={`package-form ${!hasPermission ? 'overlay' : ''}`}>
                        {alert.show &&
                            <>
                                <Alert variant={alert.variant} className="mb-5">
                                    <p>{alert.message}</p>
                                </Alert>
                            </>
                        }
                        <header className="section-header-sm section-heading section-heading--secondary">
                            <h1>Seatmap</h1>
                        </header>
                        <Form onSubmit={handleSave}>
                            <Card style={{height: '68vh'}} className="p-0">
                              <PackageActionsProvider>
                                <SeatMap
                                  data={seatmap?.mapping}
                                  setData={setData}
                                  activeTab={activeTab}
                                  selectRegularSeats={selectRegularSeats}
                                  clickGASection={clickGASection}
                                  background={seatmap?.background}
                                  selectedSeatIds={selectedSeatIds}
                                />
                              </PackageActionsProvider>
                            </Card>
                        </Form>
                    </section>

                    {showFooter && (
                        <CreatePackageButton
                          isEditing={false} 
                          isSaving={isSaving} 
                          showGoBack={false}
                          handleSave={handleSave}
                          handleGoBack={handleGoBack}
                        />
                    )}

                    {!hasPermission && (
                        <NoPermissionsContainer />
                    )}
                </div>
            )
            }
    </>
  )
}