import React, { useEffect, useState } from "react";

import { getAVMProcessStatus } from "../../utilities/api";

import Alert from "react-bootstrap/Alert";

import "./updatingAlert.scss";

export default function UpdatingAlert({ isEventPublished, eventUUID }) {
    const ALERT_DURATION = 20000; // 20 seconds in milliseconds
    const [showAlert, setShowAlert] = useState(false);

    useEffect(() => {
        if (!isEventPublished || !eventUUID) {
            return;
        }

        const checkStatus = async () => {
            try {
                const response = await getAVMProcessStatus({ jobId: eventUUID });
                console.log("status response: ", response);

                // If status is not complete, show the alert for 20 seconds
                if (response?.data?.status === "active") {
                    setShowAlert(true);

                    // Hide alert after 20 seconds
                    const timerId = setTimeout(() => {
                        setShowAlert(false);
                    }, ALERT_DURATION);

                    // Cleanup timeout if component unmounts
                    return () => {
                        clearTimeout(timerId);
                        setShowAlert(false);
                    };
                }
            } catch (error) {
                console.error("Error checking status:", error);
            }
        };

        checkStatus();
    }, [isEventPublished, eventUUID]);

    if (!showAlert) {
        return null;
    }

    return (
        <Alert variant={"primary"} className='vjx-updating-alert align-items-start'>
            <p>
                Updating your event! Updates might take a few minutes until visible to
                users.
            </p>
        </Alert>
    );
}
