import { Layer, Path, Group } from 'react-konva';
import { Seat } from "./Seat";

export default function Seats({
    data,
    setData,
    selectRegularSeats,
    activeMapAction,
    darkenColor,
    selectedSeatIds
}) {
    const handleRowAction = (rowId, hovering) => {
        // if (activeMapAction !== 2) return;

        let updatedData = { ...data };
        applyFilterToRow(rowId, hovering, updatedData.rows, updatedData.seats);

        setData(updatedData);
    };

    const selectSeat = (id) => {
        if (data.seats[id]) {
            setData((prev) => {
                let updatedSeats = { ...prev.seats };
                updatedSeats[id] = { ...updatedSeats[id], selected: true };
                selectRegularSeats(id)
                return { ...prev, seats: updatedSeats };
            });
        }
    };

    const applyFilterToRow = (rowId, hovering, rows, seats) => {
        const targetRow = rows[rowId];
        if (!targetRow) return;

        targetRow.seats.forEach(seatId => {
            const seat = seats[seatId];
            if (seat) {
                seat.hovering = hovering;
            }
        });
    };

    const seatMouseToggle = (seatId, hovering) => {
        setData((prev) => {
            let updatedSeats = { ...prev.seats };
            updatedSeats[seatId] = { ...updatedSeats[seatId], hovering: hovering };
            return { ...prev, seats: updatedSeats };
        });
    }

    const getHovering = (seat) => {
        if (seat?.hovering) {
            return true;
        }
        return false;
    }

    const handleRowSelect = (rowId) => {
        // if (activeMapAction !== 2) return;

        let updatedData = { ...data };
        let rowSeatIds = getSeatIdsForRow(rowId, updatedData.rows, updatedData.seats);

        selectRegularSeats(undefined, rowSeatIds);
        console.log()
        setData(updatedData);
    }

    const getSeatIdsForRow = (rowId, rows, seats) => {
        const targetRow = rows[rowId];
        if (!targetRow) return []; // Return empty array if the row is not found

        return targetRow.seats
            .filter(seatId => {
                if (!seats[seatId]) {
                    return false;
                };
                // dont include sold seats
                // if (inventory.getSeatItem(seatId) && inventory.getSeatItem(seatId)?.sold) {
                //     return false;
                // }
                seats[seatId].selected = true;
                return true;
            });
    };

    return (
        <Layer className='seats'>
            {data?.rows && Object.values(data.rows).map((row) => (
                <Group key={row.rowId}>
                    {row.seats.map((seatId) => {
                        const seat = data.seats[seatId];
                        return (
                            <Seat
                                key={seat.seatId}
                                seat={seat}
                                selectSeat={selectSeat}
                                hovering={getHovering(seat)}
                                activeMapAction={activeMapAction}
                                darkenColor={darkenColor}
                                seatMouseToggle={seatMouseToggle}
                                selectedSeatIds={selectedSeatIds}
                            />
                        )
                    })}
                    {/* This is for selecting by row */}
                    {row?.path && (
                        <Path
                            data={row.path}
                            listening={activeMapAction === 2}
                            onClick={() => handleRowSelect(row.rowId)}
                            onMouseOver={() => handleRowAction(row.rowId, true)}
                            onMouseOut={() => handleRowAction(row.rowId, false)}
                            opacity={0}
                        />
                    )}
                </Group>
            ))}
        </Layer>
    )
}