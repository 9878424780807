import React from "react";

import {
    formatDateTime,
    formatAddress,
    formatCurrency,
    getTimezoneDate,
    sortAttractions,
} from "../../../../utilities/helpers";

import Image from "react-bootstrap/Image";
import Stack from "react-bootstrap/Stack";

import { WarningCard } from "../../../WarningCard";

import placeholder from "../../../../assets/placeholder.png";

export default function EventInfo({ event, start, isScheduled }) {
    return (
        <Stack gap={3}>
            <div className=''>
                <h2 className='vjx-pm-subheading'>Event information</h2>
                <small className='vjx-pm-paragraph'>
                    Review your event information before publishing the event
                </small>
            </div>
            <Image
                src={event?.image?.url || placeholder}
                rounded
                alt={`Cover art for ${event?.name} event`}
                width='160'
                height='160'
                className='event-image'
            />
            <Stack as='ul' gap={3} className='mt-3 small'>
                <li>
                    <p className='vjx-pm-subheading-two'>Event date</p>
                    <span className='vjx-pm-paragraph'>
                        <span>
                            {formatDateTime(
                                getTimezoneDate(start, event?.timezone),
                                "dateOnly"
                            )}
                        </span>
                        <span className='time'>
                            {formatDateTime(
                                getTimezoneDate(start, event?.timezone),
                                "timeOnly"
                            )}
                        </span>
                    </span>
                </li>
                <li>
                    <p className='vjx-pm-subheading-two'>Venue</p>
                    <span className='vjx-pm-paragraph'>
                        <span>
                            {event?.venue?.name}
                        </span>
                        <span className='loc'>{formatAddress(event?.venue)}</span>
                    </span>
                </li>
                <li>
                    <p className='vjx-pm-subheading-two'>Attractions</p>
                    <Stack as='ul' className='vjx-pm-paragraph'>
                        {sortAttractions(event?.attractions)?.map((attraction, idx) => (
                            <li key={idx}>{attraction.name}</li>
                        ))}
                    </Stack>
                </li>
                {event?.pricingLevels && (
                    <li>
                        <p className='vjx-pm-subheading-two'>Base price</p>
                        <span className='vjx-pm-paragraph'>
                            {formatCurrency(
                                Math.min(
                                    ...Object.values(event?.pricingLevels)?.map((pl) => pl.price)
                                )
                            )}
                            <span className='to'>
                                {formatCurrency(
                                    Math.max(
                                        ...Object.values(event?.pricingLevels)?.map(
                                            (pl) => pl.price
                                        )
                                    )
                                )}
                            </span>
                        </span>
                    </li>
                )}
                <li>
                    <p className='vjx-pm-subheading-two'>Event ticket limit</p>
                    <span className='vjx-pm-paragraph'>
                        Limit:{" "}
                        <span className='text-muted'>
                            {event?.globalTicketLimit || "None"}
                        </span>
                    </span>
                </li>
            </Stack>
            {!isScheduled && (
                <WarningCard text="Warning: Publishing is permanent and cannot be undone. Please review all details carefully. Your event will be visible to users within a few minutes" variant="pale" marginValue="0" />
            )}
        </Stack>
    );
}
