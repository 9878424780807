import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import LoadingContext from '../../context/Loading/Loading';
import UserContext from '../../context/User/User';
import AuthService from '../../utilities/services/auth.service';
import { getPackage } from '../../utilities/api';
import { NoPermissionsContainer } from '../NoPermissionsContainer';
import { Spinner } from '../LoadingContainer/Spinner';
import { PageLoadingContainer } from '../PageLoadingContainer';
import { Button, Card, Modal } from "react-bootstrap";
import { ActionsProvider } from './ActionsProvider';
import { InventoryProvider } from './InventoryProvider';
import Sidebar from './Sidebar';
import Seatmap from './Seatmap';

export default function AssignPackagesWrapper({ uuid }) {
  const { isLoading, showLoading, hideLoading } = useContext(LoadingContext)
  const [hasPermission, setHasPermission] = useState(true);
  const [show, setShow] = useState(false);
  const [data, setData] = useState()
  const [seatmap, setSeatmap] = useState()
  const [selectedSeatIds, setSelectedSeatIds] = useState({});
  const [background, setBackground] = useState(null);
  const inventory = [{}]

  const activeTab = "assign-seats"

  const loadPackage = () => {
    getPackage(uuid)
      .then((res) => {
        console.log('res ', res?.data.seatmap)
        setSeatmap(res?.data.seatmap);
        // setSelectedSeatIds(res?.data.seatsInPackage)
      })
      .catch((err) => console.error(err))
  }

  useEffect(() => {
    loadPackage()
  }, [])

  // save regular seated seats,,,
  const selectRegularSeats = (seatId, seatIdArray) => {
    setSelectedSeatIds((prevSelected) => {
      const newSelected = { ...prevSelected };
      if (!newSelected.seats) {
        newSelected.seats = {};
      }

      const addSeat = (id) => {
        const seatItem = getSeatItem(id);
        const key = seatItem && seatItem.key ? seatItem.key : "unassigned";

        if (!(seatItem?.name?.toLowerCase() === "sold")) {
          if (!newSelected.seats[key]) {
            newSelected.seats[key] = [];
          }
          if (!newSelected.seats[key].includes(id)) {
            newSelected.seats[key].push(id);
          }
        }
      };

      if (seatId) {
        // Handle single seat selection
        addSeat(seatId);
      } else if (seatIdArray) {
        // Handle multiple seat selection
        seatIdArray.forEach(addSeat);
      }

      return newSelected;
    });
  };

  const selectGASeats = (gaSeatsToSelect) => {
    setSelectedSeatIds((prevSelected) => {
      const newSelected = { ...prevSelected };
      if (!newSelected.gaSeats) {
        newSelected.gaSeats = {};
      }

      for (const [sectionId, fromSeats] of Object.entries(gaSeatsToSelect)) {
        // Add object for sectionId if does not exist
        if (!newSelected.gaSeats[sectionId]) {
          newSelected.gaSeats[sectionId] = {};
        }

        for (const [fromId, seats] of Object.entries(fromSeats)) {
          if (!newSelected.gaSeats[sectionId][fromId]) {
            newSelected.gaSeats[sectionId][fromId] = [];
          }

          // Filter out seats that already exist before pushing
          const existingSeats = new Set(newSelected.gaSeats[sectionId][fromId]);
          const uniqueNewSeats = seats.filter(
            (seat) => !existingSeats.has(seat)
          );

          newSelected.gaSeats[sectionId][fromId].push(...uniqueNewSeats);
        }
      }

      return newSelected;
    });
  };

  // GA section is clicked, do something
  const clickGASection = (section) => {
    // Also check if section is already selected, if so return

    const fromIdKey = "unassigned";
    const addToGASeats = {
      [section.sectionId]: {
        [fromIdKey]: section.spots,
      },
    };

    selectGASeats(addToGASeats);
  };

  const getSeatItem = (seatId) => {
    console.log('getSeatItem '. getSeatItem)
    return seatId
    // Check if selected
    let seatItem;
    // check other lookups
    
    return seatItem;
  };

  return (
    <>
      {isLoading ? (
                <PageLoadingContainer />
            ) : (
              <>
              <div className='position-relative'>
                <section
                  className={`max-width-wrapper event-form ${
                    !hasPermission ? "overlay" : ""
                  }`}
                >
                  <section>
                      <header className='section-header-sm section-heading--flex section-heading section-heading--secondary'>
                        <div className="section-header" >
                            <div className="section-heading">
                                <h1>Assign Package</h1>
                            </div>
                            <p className='section-header-desc'>Manually add your fan information for package tickets</p>
                        </div>
                        {true && (
                          <Button className={`btn`} onClick={() => setShow(true)}>+ Assign Package</Button>
                        )}
                      </header>
                      <h4>Something</h4>
                    </section>
                </section>

                {!hasPermission && <NoPermissionsContainer />}
              </div>
            </>
      )}

      <Modal show={show} fullscreen={true} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Modal</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Sidebar />
          <ActionsProvider>
            <InventoryProvider activeTab={activeTab} packageUUID={uuid}>
              <Seatmap
                data={seatmap?.mapping}
                activeTab={activeTab}
                selectRegularSeats={selectRegularSeats}
                clickGASection={clickGASection}
                background={seatmap?.background}
              />
            </InventoryProvider>
          </ActionsProvider>
        </Modal.Body>
      </Modal>
    </>
  )
}