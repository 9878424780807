import React, { useState, useEffect } from 'react';

import { searchAttendees } from '../../../utilities/api';

import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Stack from "react-bootstrap/Stack";
import Card from "react-bootstrap/Card";

import { SearchBar } from '../../SearchBar';
import { ExportBtn } from "../../ExportBtn";
import { Attendee } from './Attendee';
import { EmptyContainer } from '../../EmptyContainer';
import { LoadingContainer } from '../../LoadingContainer';

export default function AttendeeList({ eventTimezone, eventOffers, attendees, eventId, checkInAttendee, hasEventEnded }) {

    const listOpt = [
        {
            label: "All",
            value: 'all'
        },
        {
            label: "Check in",
            value: 'checkIn'
        },
        {
            label: "Checked in",
            value: 'checkedIn'
        },
    ]

    const [isLoading, setIsLoading] = useState(false)

    const [filteredAttendees, setFilteredAttendees] = useState(attendees)

    const [query, setQuery] = useState('')

    const [filter, setFilter] = useState(listOpt[0].value)

    useEffect(() => {
        setIsLoading(true)

        searchAttendees(eventId, query)
            .then((res) => {
                if (filter === "all") setFilteredAttendees(res.data)
                if (filter === "checkIn") setFilteredAttendees(res.data?.filter(d => d.checkedIn === false))
                if (filter === "checkedIn") setFilteredAttendees(res.data?.filter(d => d.checkedIn === true))
                setIsLoading(false)
            })
            .catch((err) => {
                console.error(err)
                setIsLoading(false)
            })

    }, [attendees, query, filter])

    // sort by alphabetical order
    const sortBy = (arr) => {
        return arr.sort((a, b) => a?.users_permissions_user.firstName.toLowerCase().localeCompare(b?.users_permissions_user.firstName.toLowerCase()))
    };

    const getText = () => {
        const obj = listOpt.find(opt => opt.value === filter)

        if (obj.value == 'all') {
            return
        } else {
            return obj.label.toLowerCase()
        }
    }

    const handleClick = a => {
        checkInAttendee(a)
    }

    return (
        <>
            <header className="section-header">
                <div className='actions-group-flex'>
                    <FloatingLabel controlId="list-status" label="List status">
                        <Form.Select value={filter} onChange={(e) => setFilter(e.target.value)}>
                            {listOpt.map((option, index) => (
                                <option key={index} value={option.value}>
                                    {option.label}
                                </option>
                            ))}
                        </Form.Select>
                    </FloatingLabel>
                    <SearchBar getQuery={setQuery} placeholder="Search by name, phone number or email" />
                    <ExportBtn data={filteredAttendees} timezone={eventTimezone} offers={eventOffers} exportedData="attendees" />
                </div>
            </header>
            {isLoading ? (
                <LoadingContainer />
            ) : (
                <>
                    {filteredAttendees.length > 0 ? (
                        <Stack as="ul" gap={3}>
                            {sortBy(filteredAttendees).map(attendee => (
                                <Attendee key={attendee.id} timezone={eventTimezone} offers={eventOffers} attendee={attendee} handleClick={handleClick} hasEventEnded={hasEventEnded} />
                            ))}
                        </Stack>
                    ) : (
                        <>
                            <Card body>
                                <EmptyContainer style="center lg">
                                    <p>This event has no {getText()} attendees {query && `starting with '${query}'`}. Please try again.</p>
                                </EmptyContainer>
                            </Card>
                        </>
                    )}
                </>
            )}
        </>
    );
}
