import React, { useEffect, useState } from "react";
import { Rect } from "react-konva";
import chroma from "chroma-js";
import { mapStateManager } from "../../MapStateManager";

function Seat({ seat, getSeatItem }) {
  const seatInfo = getSeatItem(seat.seatId);
  const darkenColor = (color, amount) => chroma(color).darken(amount).hex();
  const isSeatSold = seatInfo?.name?.toLowerCase() === "sold";
  const seatColor = seatInfo?.color || "#E6E8EC";
  const [seatState, setSeatState] = useState(mapStateManager.getState(seat.seatId));

  useEffect(() => {
    return mapStateManager.subscribe((updatedSeatId) => {
      if (updatedSeatId === seat.seatId) {
        setSeatState(mapStateManager.getState(seat.seatId))
      }
    })
  }, [seat])

  return (
    <Rect
      x={seat.cx}
      y={seat.cy}
      id={seat.seatId}
      cornerRadius={20}
      className='seat'
      width={seat.w}
      height={seat.h}
      fill={seatState?.selected && !isSeatSold ? darkenColor(seatColor) : seatColor}
      // Improved perf, hopefully
      transformsEnabled={"position"}
      perfectDrawEnabled={false}
      listening={!isSeatSold}
    ></Rect>
  );
}

export default React.memo(Seat);
